export default defineNuxtRouteMiddleware(async (to, _from) => {
	const articleStore = useArticleStore();
	const { selectedArticle, categories } = storeToRefs(articleStore);

	if (!selectedArticle.value) {
		await articleStore.getKnowledgeDetailById(to.params.id);
	}

	if (categories.value.length === 0) {
		await articleStore.getKnowledgeCategories();
	}
});
